<template> 
    <Dialog id="sendEmailModal" header="Send Email" v-model:visible="display" :modal="true" :closable="true" class='send-email-modal' @hide="close">
       
         <template v-if="selectedGroup.educational ">
            <Dropdown style="border: 2px solid #33CC99;"  :options="audienceOptions" v-model="selectedAudience" optionLabel="name" placeholder="Select Audience"/>
            <template v-if="selectedAudience?.name == 'Course Cohort'">
                <Dropdown style="border: 2px solid #33CC99;margin-left:5px;"  :options="courses" optionLabel="name" v-model="selectedCourse" placeholder="Select Course"/>
                <Dropdown style="border: 2px solid #33CC99;margin-left:5px;"  v-model="selectedCohort" :options="cohorts" placeholder="Select Cohort" :loading="loadingCohorts">
                <template #value="slotProps">
                    <div v-if="slotProps.value" >
                        <div>{{getCohortLabel(slotProps.value) }}</div>
                    </div>
                    <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                </template>
                <template #option="slotProps">
                    
                        <div >{{ getCohortLabel(slotProps.option)}}</div>
                    
                </template>
            </Dropdown>
                
                </template>

        </template>
        <template v-else>
                <span class='body-text'>Send an email to all group members.</span>
        </template>
        <template #footer>
            <span class='p-float-label'>
                <InputText id='subject' type="text" v-model="subject" class="subject-input" maxlength="60"/>
                <label for="subject" class='input-label'>Subject</label>
            </span>
           
            
             <vue-editor id="emailEditor" ref="emailEditor" v-model="message" :editorOptions="createEmailEditorSettings('#sendEmailModal')"  
                                :customModules="editorModules" />
           
            <div class='button-container'>
                <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close'/>
                <Button label="Send Test Email" class='accept p-button-raised p-button-rounded' @click='openEmailAddressInputModal' :disabled="!message || !subject"/>
                <Button label="Submit" class='accept p-button-raised p-button-rounded' @click='submitEmail' :loading="sending" :disabled="!message || !subject"/>
            </div>
        </template>
        <Dialog class='enter-test-emails-modal' header="Enter Emails" v-model:visible="displaySendingTestEmailModal" :modal="true" :closeable="true">
            <span class='body-text'>Send test email up to 5 email addresses (Optional) </span>
            <template #footer>
                <InputText type="email" v-model="emailAddresses[0]" class="email-input"/>
                <InputText type="email" v-model="emailAddresses[1]" class="email-input"/>
                <InputText type="email" v-model="emailAddresses[2]" class="email-input"/>
                <InputText type="email" v-model="emailAddresses[3]" class="email-input"/>
                <InputText type="email" v-model="emailAddresses[4]" class="email-input"/>

                <div class='button-container'>
                    <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='displaySendingTestEmailModal = false'/>
                    <Button label="Submit" class='accept p-button-raised p-button-rounded' @click='sendTestEmail' :loading="sendingTestEmail"/>
                </div>
            </template>
        </Dialog>
    </Dialog>
</template>

<script>

import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

import GroupService from '../../../service/GroupService';
import { VueEditor } from "vue3-editor";
import { createEmailEditorSettings, emailEditorModules} from "../../editor/config.js";
import format from 'date-fns/format';
import Dropdown from 'primevue/dropdown';

export default {
    name: 'SendEmailModal',
    props: {
        selectedGroup: {
            type: Object,
            required: true
        }
    },
	data() {
		return {
			display: false,
            displaySendingTestEmailModal: false,
            subject: null,
            message: null,
            sending: false,
            sendingTestEmail: false,

          
            renderedEditor: null,

            emailAddresses: [this.$store.getters['users/getUser'].username, null, null, null, null],

            createEmailEditorSettings: createEmailEditorSettings,
            editorModules: emailEditorModules,
            selectedAudience: null,
            selectedCourse: null,
            selectedCohort:null,
            audienceOptions: [{name :"All Members"}, {name:"Course Cohort"}],
            courses: [],
            cohorts: [],
            loadingCourses: false,
            loadingCohorts: false,
            
		}
	},
	components: {
		Dialog, InputText, VueEditor,
        Dropdown,
	},

    watch: {

        selectedAudience(newVal, oldVal) {
            if( newVal && newVal != oldVal && this.display ) {
                this.selectedCohort = null;
               
                this.loadCourses(this.selectedGroup);
            }
        },
    
        selectedCourse(newVal, oldVal) {//eslint-disable-line
            if( newVal && newVal != oldVal && this.display ) {
                this.selectedCohort = null;
               
                this.loadCohorts(newVal);
            }
        },
    },

	methods: {
        open() {
            
            this.subject = null;
            this.message = null;
            this.sending = false;
            this.selectedAudience = null;
            this.selectedCourse = null;
            this.selectedCohort = null;
            this.loadingCohorts = false;
            this.loadingCourses = false;
            this.courses = [];
            this.cohorts = [];
            this.display = true;
           
        },

		close() {
			this.display = false;
            this.subject = null;
            this.message = null;
            this.sending = false;
            this.selectedAudience = null;
            this.selectedCourse = null;
            this.selectedCohort = null;
            
            this.loadingCohorts = false;
            this.loadingCourses = false;
            this.courses = [];
            this.cohorts = [];
		},


         getCohortLabel(cohort) {
            return "Cohort "+ format(new Date(cohort.startDate), 'yyyy-MM-dd') +" - " + format(new Date(cohort.endDate), 'yyyy-MM-dd')
        },

        loadCohorts(course) {
            console.log("loading cohorts for coures:", course);
            this.loadingCohorts = true;
             GroupService.getAllCourseCohorts(course).then(resp => {
                if( resp.data.status == "success") {
                    this.cohorts = resp.data.cohorts;
                }
                else {
                    console.log("error getting cohorts: "+ resp.data.message);
                }
                this.loadingCohorts = false;
             });

        },
        loadCourses(group) {
            this.loadingCourses = true;
            GroupService.getGroupCourses(group.groupId).then(resp => {
                if (resp.data.status == "success") {
               
                    if (resp?.data?.courses?.length > 0 ) {
                        this.courses = resp.data.courses;
                    }
               
                }
                else {
                    console.error("error getting group courses :" + resp.data.message)
                }
                this.loadingCourses = false;
            });
        },

        openEmailAddressInputModal() {
            this.displaySendingTestEmailModal = true;
        },

        

        sendTestEmail() {
            this.$toast.removeAllGroups();
            const emails = this.emailAddresses.filter(email => email !== null);
            this.sendingTestEmail = true;

            GroupService.sendEmailToGroupMembers(this.selectedGroup.groupId, this.subject, this.message, emails, this.selectedCohort).then(resp => {
                if( resp.data.status == "success") {
                    this.$toast.add({ severity: 'success', summary: 'Test email successfully sent!', life: 30000, group: 'center' });
                    

                    this.sendingTestEmail = false;
                    this.displaySendingTestEmailModal = false;
                }
                else {
                    this.$toast.add({ severity: 'error', summary: 'An error occurred sending test email.  Please try again.  If the problem persists please send an email to support@invrs.com.', life: 10000, group: 'center' });
                    this.sendingTestEmail = false;
                }
            }).catch(err => {
                console.error("Error sending test email "+err);
                this.$toast.add({ severity: 'error', summary: 'An error occurred sending test email.  Please try again.  If the problem persists please send an email to support@invrs.com.', life: 10000, group: 'center' });
                this.sendingTestEmail = false;
            });
        },
       
        submitEmail() {
            this.sending = true;
            this.$toast.removeAllGroups()
            // if( this.subject == null || this.subject.trim() == '' ){
            //     this.$toast.add({ severity: 'error', summary: 'Please enter a subject.', life: 5000, group: 'center' });
            //     this.sending = false;  
                
            // }
            // else if( this.message == null || this.message.trim() == '' ){
            //     this.$toast.add({ severity: 'error', summary: 'Please enter a message.', life: 5000, group: 'center' });
            //     this.sending = false;  
            //     return;
            // }

            GroupService.sendEmailToGroupMembers(this.selectedGroup.groupId, this.subject, this.message, [], this.selectedCohort).then(resp => {
                if( resp.data.status == "success") {
                    this.$toast.add({ severity: 'success', summary: 'Email successfully sent!', life: 30000, group: 'center' });
                    
                    this.close();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: 'An error occurred sending the email.  Please try again.  If the problem persists please send an email to support@invrs.com.', life: 10000, group: 'center' });
                    this.sending = false;
                }
            }).catch(err => {
                console.error("Error sending email "+err);
                this.$toast.add({ severity: 'error', summary: 'An error occurred sending email.  Please try again.  If the problem persists please send an email to support@invrs.com.', life: 10000, group: 'center' });
                this.sending = false;
            });
           
                
        },
       
        
	}
}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.send-email-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 800px;
	box-shadow: none;
}
.enter-test-emails-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
	box-shadow: none;
}

.enter-test-emails-modal .p-dialog-header,
.send-email-modal .p-dialog-header {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
}

.enter-test-emails-modal .p-dialog-header .p-dialog-title,
.send-email-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

.enter-test-emails-modal .p-dialog-content,
.send-email-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 10px 25px;
    overflow-y: visible;
}
.enter-test-emails-modal .body-text,
.send-email-modal .body-text {
    font-size: 16px;
    color: #a6a6a6;
}

.enter-test-emails-modal .p-dialog-footer,
.send-email-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

.send-email-modal .ck.ck-toolbar {
  background: #F2F4FA;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.send-email-modal .ck.ck-content {
  font-size: 16px;
  color: black;
  padding: 0 30px;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  min-height: 325px;
  max-height: 325px;
}

.send-email-modal .ck.ck-reset_all, .send-email-modal .ck.ck-reset_all * {
  font-family: 'Trebuchet MS', 'Verdana' !important;
}

.send-email-modal .ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  margin-left: 15px;
}

.send-email-modal .ck.ck-content a {
  color: #5367FF;
  font-weight: bold;
}

.send-email-modal .ck-editor__editable_inline::-webkit-scrollbar {
	/* width */
	width: 8px;
    
}
.send-email-modal .ck-editor__editable_inline::-webkit-scrollbar-track {
	margin-right: 10px;
}
.send-email-modal .ck-editor__editable_inline::-webkit-scrollbar-thumb {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}


.send-email-modal  .ck-editor__editable_inline.ck-focused {
    border-color: #33CC99 !important;
}
</style>
<style scoped>
.send-email-modal .subject-input {
    width: 100%;
    font-size: 16px;
    border-radius: 15px;
    /* border: 2px solid #BFBFBF; */
    /* background-color: #F2F4FA; */
    padding-left: 20px;
}
.send-email-modal .subject-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.email-input {
    width: 100%;
    font-size: 16px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
    margin-bottom: 8px;
}
.email-input:last-child {
    margin-bottom: 0;
}

.send-email-modal .message-input {
    width: 100%;
    font-size: 16px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
}
.email-input:enabled:focus,
.send-email-modal .message-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
    box-shadow: none;
}
.p-float-label {
    margin: 15px 0px 25px;
}
.input-label {
    font-size: 16px;
    padding-left: 20px;
    line-height: 0.8;
}

.p-dialog-footer .p-button {
    font-size: 16px;
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

.p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 10px;
 }

.p-dialog-footer .accept {
    border-color: #33CC99;
    padding: 10px 30px;
 }

.p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33CC99;
 }
.p-dialog-footer .accept:focus {
     box-shadow: none;
     border-color: #33CC99;
 }

.p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
 } 
.p-dialog-footer .cancel:focus {
     box-shadow: none;
     border-color: #32364E;
 }

 .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
</style>